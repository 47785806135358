<template>
  <accounts-info
    method="listMyAccounts"
    :accountsPerPage='8'
  />
</template>

<script>

import Analytics from '@aws-amplify/analytics';
import AccountsInfo from '../components/accounts-info';

export default {
  name: 'my_accounts',
  data() {
    return {
      tmpAcctRole: '',
    };
  },
  components: {
    AccountsInfo
  },
  async created() {
    Analytics.record('Access_accounts_page');
  },
};
</script>

<style scoped>

</style>
